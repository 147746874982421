<template>
  <div>
    <div>
      <div id="pageEditEpisode" class="mainContents">
        <UserNavSp />

        <UserTitle :user="user" />

        <UserNav :user="user" />

        <EpisodeForm ref="episodeForm" :prop-episode="episode" />
      </div>
    </div>
  </div>
</template>

<script>
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'
import EpisodeForm from '@/components/EpisodeForm.vue'

export default {
  components: {
    EpisodeForm,
    UserTitle,
    UserNav,
    UserNavSp
  },
  data () {
    return {
      episode: this.createFreshEpisodeObject(),
      isUploading: false,
      user: {}
    }
  },
  beforeDestroy () {},
  created: function () {
    this.user = this.$store.getters.getLoggedInUser
  },
  mounted () {},
  methods: {
    createFreshEpisodeObject () {
      return {
        uniqid: '',
        title: '',
        description: '',
        message: '',
        time: '1',
        year: '',
        month: '',
        day: '',
        age: '',
        date: '',
        display: 'pri',
        allow_comments: true
      }
    }
  }
}
</script>

<style scoped>
.field {
  margin-bottom: 24px;
}
</style>
